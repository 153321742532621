.contact-container {
    width: 100vw;
    max-width: 1400px; /* Slightly narrower max width for professional spacing */
    margin: 0 auto;
    padding: 40px 5%;
    background: #2c3e50;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    text-align: left; /* Align text to the left */
    color: white;
}

.contact-container h2 {
    font-size: 2.2rem;
    margin-bottom: 20px;
    font-weight: 700;
    color: white;
    text-align: left;
    padding-left: 0; /* Remove padding for clean look */
}

.contact-info {
    display: block; /* Stack elements vertically */
    padding: 0;
}

.contact-item {
    margin-bottom: 30px;
    background-color: #2c3e50;
    color: white;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    transition: transform 0.2s ease-in-out;
}

.contact-item h3 {
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0;
    border-bottom: 1px solid #555;
    padding-bottom: 5px;
}

.contact-item p {
    font-size: 1.1rem;
    margin: 0;
}

.contact-item a {
    color: white; /* Lighter blue for links */
    text-decoration: none;
    font-weight: 500;
}

.contact-item a:hover {
    text-decoration: underline;
}

.social-links {
    display: inline-block;
    margin: 0;
}

.social-links a {
    display: inline-block;
    margin-right: 20px; /* Add space between links */
    text-decoration: none;
    font-size: 1.5rem;
    color: #1e88e5;
}

.social-links a:last-child {
    margin-right: 0; /* Remove margin from the last link */
}

.social-links a:hover {
    color: #1565c0;
}

.footer {
    border-top: 2px solid #777;
    color: #ccc;
    font-size: 1.2rem;
    text-align: left;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .contact-item {
        margin-bottom: 20px;
    }
    
    .social-links a {
        font-size: 1.4rem;
    }
}
