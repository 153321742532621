.industry-solutions {
  max-width: 1600px; /* Maximum width for the showcase */
  margin: 0 auto; /* Centering the content */
  padding-top: 80px; /* Padding around the content */
  padding-bottom: 0;
  font-family: Arial, sans-serif; /* Font style */
  color: #333; /* Text color */
  background-color: white;
}

/* Hero Section */
.industry-title {
  text-align: center;
  padding: 60px 40px;
  background-color: #f8f6f3;
  color: #66ccff;
  margin-top: 0;
  height: 75vh;
  animation: fadeIn 1s ease-in-out;
  margin-bottom: 80px;
}

.industry-title h1 {
  font-size: 80px;
  margin-bottom: 15px;
  letter-spacing: 2px;
}

.industry-title p {
  font-size: 22px;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.8;
  color: rgb(93, 124, 152);
}


h1 {
  text-align: center; /* Center the main heading */
  font-size: 2.5rem; /* Font size for the main heading */
  margin-bottom: 10px; /* Space below the main heading */
}

.industry-section {
  margin-bottom: 80px; /* Space between industry sections */
  background-color: white; /* Light background color */
  width: 100%;
}


.industry-section h2 {
  font-size: 6rem; /* Font size for industry headings */
  margin-bottom: 10px; /* Space below industry heading */
  padding-top: 60px;
  padding-left: 60px;
}

.industry-categories {
  display: flex; /* Flexbox for layout */
  justify-content: space-between; /* Space between categories */
  flex-wrap: wrap; /* Wrap categories to the next line if necessary */
  padding-left: 40px;
  padding-right: 40px;
}

.industry-category {
  flex: 1; /* Equal width for each category */
  min-width: 220px; /* Minimum width for responsiveness */
  margin: 20px; /* Space around each category */
  padding: 40px; /* Padding inside each category */
  border: none; /* Removing solid border for a cleaner look */
  border-radius: 10px; /* Slightly more rounded corners for a modern feel */
  background-color: #ffffff; /* White background for categories */
  background: linear-gradient(145deg, #f9f9f9, #e0e0e0); /* Subtle background gradient for a modern effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); /* Soft shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for effects */
}


.industry-category:hover {
  transform: translateY(-10px); /* Slight lift effect on hover */
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.1); /* Stronger shadow on hover */
  background: linear-gradient(145deg, #ffffff, #eaeaea); /* Slightly lighter gradient on hover */
}

.industry-category:active {
  transform: translateY(2px); /* Slight press effect */
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2); /* Softer shadow when pressed */
  background: linear-gradient(145deg, #eaeaea, #d4d4d4); /* Darker gradient when pressed */
}


.industry-category h3 {
  font-size: 1.5rem; /* Font size for category headings */
  margin-bottom: 8px; /* Space below category heading */
}

.industry-category p {
  font-size: 1rem; /* Font size for category descriptions */
  line-height: 1.5; /* Line height for readability */
}

.learn-more-button {
  display: inline-block;
  margin-top: 20px;
  margin-left: 60px;
  margin-bottom: 40px;
  padding: 18px 80px;
  background-color: rgb(99, 98, 80);
  background: linear-gradient(145deg, #716929, #535043);
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 1px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease, 
              scale 0.3s ease;
  text-decoration: none;
}

.learn-more-button:hover {
  transform: scale(1.05); /* Slight zoom effect */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3), 0 2px 8px rgba(0, 0, 0, 0.2);
  background: linear-gradient(145deg, #78745c, #5a5647);
}

.learn-more-button:active {
  transform: scale(0.98); /* Shrinks slightly when pressed */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
  background: linear-gradient(145deg, #5a5647, #4a4638);
}

.contact-two{
  width: 100%;
  margin-left: -40px;
}


/* Responsive Styles */
@media (max-width: 768px) {
  .industry-categories {
    flex-direction: column; /* Stack categories on smaller screens */
    align-items: center; /* Center align categories */
  }

  .industry-category {
    width: 90%; /* Full width for categories on small screens */
    margin: 10px 0; /* Space between stacked categories */
  }
}
