@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.industries-heading {
  margin-bottom: 40px;
  margin-top: -50px;
  opacity: 0;
  transform: translateY(20px); /* Start off slightly below */
  transition: opacity 0.8s ease, transform 0.8s ease;
  font-size: 3rem;
  font-weight: bold;
  color: rgb(114, 114, 114);
}

.industries-heading.visible {
  opacity: 1;
  transform: translateY(0); /* Reset to normal position */
  animation: fadeInUp 2s ease forwards; /* Add animation */
}

.full-width-background3 {
  background-color: #f9f9f9;
  padding: 60px 100px;
  text-align: center;

}

.new-section-content3 {
  max-width: 1200px;
  margin: 0 auto;
}

.industries-list {
  position: relative;
  height: 350px;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: -20px;
}

.industry-image-container {
  position: absolute;
  width: 100%;
  height: 100%;
  margin-top: -40px;
  opacity: 0;
  transform: translateX(100%);
  transition: transform 0.8s ease, opacity 0.8s ease;
  display: block;
}

.industry-image-container.active {
  opacity: 1;
  transform: translateX(0);
}

.industry-image {
  width: 100%;
  height: 100%;
  object-fit: cover;

}

.industry-name-overlay {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  background-color: rgba(200, 112, 40, 0.6);
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .industries-list {
  height: 200px;
  }
  .industry-image-container {
    position: absolute;
    width: 100%;
    height: 100%;
    margin-top: -40px;
    opacity: 0;
    transform: translateX(100%);
    transition: transform 0.8s ease, opacity 0.8s ease;
    display: block;
  }

}

/* Responsive Design */
@media (max-width: 200px) {
  .part h2 {
    font-size: 1rem;
  }

}
