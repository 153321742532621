/* Make Contact Form Container Fullscreen */
.contact-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 20px;
    padding-top: 120px;
    box-sizing: border-box;
    background: #2c3e50;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

/* Title Styling with Background Effect */
.contact-form-container h2 {
    font-size: 1.75rem;
    color: #ffffff;
    font-weight: 600;
    padding: 12px 24px; /* Adds space around the text */
    margin-bottom: 24px;
    border-radius: 8px; /* Rounded corners */
    text-transform: uppercase;
    letter-spacing: 1px;
    align-items: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    display: inline-block; /* Centers the background width around text */
}

/* Success Message */
.success-message {
    color: #28a745;
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 1rem;
    background-color: #e9ffe5;
    padding: 8px 16px;
    border-radius: 8px;
}

/* Form Styling */
form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px; /* Vertical gap between form groups */
    width: 100%;
    max-width: 800px;
    padding: 10px;
}

/* Two-column Layout for Larger Screens */
@media (min-width: 768px) {
    form {
        grid-template-columns: 1fr 1fr; /* Two columns */
        gap: 10px 150px; /* Vertical and horizontal gap */
    }
}

/* Full-width Elements */
.form-group {
    width: 100%;
    padding: 10px; /* Adds padding around each form group for better spacing */
}

.form-group:nth-child(odd),
.form-group:nth-child(even) {
    grid-column: span 1;
}

/* Make the Textarea Full Width */
textarea {
    grid-column: span 2;
    resize: vertical;
}

/* Label Styling */
.form-group label {
    font-size: 1rem;
    font-weight: 600;
    color: #6189ce;
    display: block;
    margin-bottom: 8px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

/* Input and Textarea Styling */
input,
textarea {
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    border: 1px solid #d0d4e6;
    border-radius: 10px;
    background-color: #a9b3c4;
    transition: all 0.3s ease;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
}

input:focus,
textarea:focus {
    outline: none;
    border-color: #007bff;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.2);
}

/* Error Message */
.error {
    color: #dc3545;
    font-size: 0.85rem;
    margin-top: 4px;
    font-weight: 500;
}

/* Stylish Button Styling */
.contact-button {
    width: 100%;
    max-width: 400px; /* Adjust width for better centering */
    padding: 14px;
    font-size: 1rem;
    color: #fff;
    background: linear-gradient(135deg, #007bff, #004aad);
    border: none;
    border-radius: 50px;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 6px 12px rgba(0, 123, 255, 0.2);
    letter-spacing: 0.5px;
    text-transform: uppercase;
    grid-column: span 2; /* Span across two columns */
    justify-self: center; /* Center the button */
    margin-top: 15px;
}

.contact-button:hover {
    background: linear-gradient(135deg, #0056b3, #003f7f);
    box-shadow: 0 8px 16px rgba(0, 123, 255, 0.3);
}

.contact-button:active {
    transform: scale(0.97);
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.15);
}

/* Multi-Select Container */
.multi-select-container {
    position: relative;
    width: 100%; /* Ensure it takes full width available */
}

/* Multi-Select Input */
.multi-select-input {
    padding: 12px;
    border: 1px solid #d0d4e6;
    border-radius: 10px;
    background-color: #a9b3c4;
    color: #333;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
    font-size: 1rem;
    font-weight: 500;
    width: 100%; /* Make input take full container width */
    max-width: 500px; /* Set max-width as desired */
}

/* Dropdown Style */
.multi-select-dropdown {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    width: 100%; /* Ensure dropdown matches input width */
    max-width: 500px; /* Set max-width to match the input */
    border: 1px solid #d0d4e6;
    border-radius: 10px;
    background-color: #ffffff;
    padding: 10px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 10;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Dropdown Option Labels */
.multi-select-dropdown label {
    display: flex;
    align-items: center;
    padding: 12px;
    cursor: pointer;
    color: #333;
    font-size: 1rem;
    font-weight: 500;
    transition: background-color 0.3s ease, color 0.3s ease;
    border-radius: 8px;
}

/* Dropdown Option Hover Effect */
.multi-select-dropdown label:hover {
    background-color: #e8f0fe;
    color: #004aad;
}

/* Checkbox Styles */
.multi-select-dropdown input[type="checkbox"] {
    width: 20px;
    height: 20px;
    accent-color: #007bff;
    margin-right: 12px;
}

/* Selected State for Input */
.multi-select-input.selected {
    background-color: #e0f7fa;
    color: #004aad;
}
