
/* Base styling */
.saffroot-info {
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 100px;
  font-family: 'Roboto', Arial, sans-serif;
  line-height: 1.7;
  color: #333;
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;
  margin-left: -80px;
}

.intro {
  text-align: center;
  margin-bottom: 40px;
  padding: 20px;
  background: white;
}

.intro h1 {
  font-size: 4.5rem;
  color: #2c3e50;
  font-weight: bold;
  letter-spacing: 1px;
}

.intro p {
  max-width: 1000px;
  margin: 0 auto;
  font-size: 1.4rem;
  color: #555;
  padding-bottom: 60px;
}

/* Solution Section Styling */
.solution-section {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 40px;
  padding: 40px;
  background: linear-gradient(135deg, #f0f4f8, #ffffff);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  overflow: hidden;
}

.more-space {
  margin-top: 100px; /* Ensures 100px margin appears above Contact */
}
/* Left and right column width adjustments */
.left-column {
  flex: 0 0 30%;
  background: #f7f9fc;
  border-right: 8px solid #e0e4e8;
  padding: 30px;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-sizing: border-box;
}

.right-column {
  flex: 0 0 70%;
  background: #ffffff;
  padding: 20px;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-sizing: border-box;
}

/* Text styling */
.left-column h2, .right-column h2 {
  font-size: 2.2rem;
  color: #025084;
  font-weight: bold;
  margin-bottom: 15px;
  border-bottom: 3px solid #025084;
  padding-bottom: 8px;
  padding: 0;
}

.left-column p, .right-column p {
  font-size: 1.1rem;
  color: #666;
  line-height: 1.8;
  margin-bottom: 20px;
}

/* Hover effect for columns */
.left-column:hover, .right-column:hover {
  transform: translateY(-5px);
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.1);
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
  .solution-section {
    flex-direction: column;
    padding: 20px;
  }

  .left-column, .right-column {
    flex: 1 1 100%;
    border-right: none;
    border-bottom: 2px solid #e0e4e8;
    padding: 20px;
  }
}

/* Animation styling */
.fade-item {
  background: #ffffff;
  padding: 15px 20px;
  margin: 10px 0;
  border-radius: 6px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.fade-item:hover {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease;
}

/* Typography enhancements */
.fade-item::before {
  font-size: 1.2rem;
  color: #3498db;
  padding-right: 5px;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .solution-section {
    flex-direction: column;
  }

  .left-column h2 {
    font-size: 1.8rem;
  }

  .intro h1 {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .left-column h2 {
    font-size: 1.6rem;
  }

  .intro h1 {
    font-size: 1.8rem;
  }
}
