/* Active Link Styling */
.active-link {
  color: #e67839 !important; /* Accent color for active link */
}

/* Ribbon Styles */
.ribbon {
  background-color: rgb(66, 69, 70);
  padding: 20px 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  z-index: 999;
  height: 50px; /* Set a fixed height */
}

.logo-container {
  display: flex;
  align-items: center;
}

.home-icon {
  width: 130px;
  height: auto;
  transition: transform 0.3s ease;
  padding-top: 5px;
}

.home-icon:hover {
  transform: scale(1.05);
}

/* Menu styles */
.menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  padding-right: 80px;
}

.menu li {
  margin: 0 30px;
}

.menu a {
  color: #b2b4b6;
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.5px;
  transition: color 0.3s ease, border-bottom 0.3s ease;
}

.menu a:hover {
  color: #e67839;
}

/* Hamburger Button */
.hamburger {
  display: none;
  font-size: 24px;
  background: none;
  border: none;
  color: #b2b4b6;
  cursor: pointer;
  margin-left: auto;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .menu {
    display: none; /* Hide menu by default on smaller screens */
    flex-direction: column;
    background-color: rgb(66, 69, 70);
    position: absolute;
    top: 80px; /* Adjust to be below the ribbon */
    width: 100%;
    left: 0;
    padding: 10px 0;
  }

  .menu.open {
    display: flex; /* Show menu when hamburger is clicked */
  }

  .menu li {
    margin: 10px 0;
  }

  .hamburger {
    display: block; /* Show hamburger on smaller screens */
  }
}

/* Main Content adjustment below the ribbon */
.main-content {
  padding-top: 100px; /* This should match or exceed the ribbon height */
}

@media (max-width: 768px) {
  .main-content {
    padding-top: 150px; /* Adjust for smaller screens if ribbon height changes */
  }
}
