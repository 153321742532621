/* Resetting margin and padding for body and html */
html, body {
  margin: 0px;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto Condensed', sans-serif;
}

.main-home-container {
position: relative;
overflow: hidden;
margin: 0;
padding: 0;
margin-top: 78px; /* Adjust to the actual height of your Ribbon */
}

/* Common Styling for All Parts */
.part {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
}

/* Full-Width Background for First Part */
.full-width-background1 {
position: relative;
overflow: hidden;
display: flex;
justify-content: center;
align-items: center;
height: 100vh; /* Make sure it covers the full height of the viewport */
margin: 0;
padding: 0;
background: #f5f5f5;
}

/* Carousel Styling */
.carousel {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
overflow: hidden;
z-index: 0;
}

/* Ribbon Styling */
.carousel::after {
content: "";
position: fixed;
bottom: 0;
left: 0;
width: 100%;
height: 8%;
background: #ffffff;
z-index: 2; /* Higher z-index to be above other elements */
}

/* Animation for Image Transition */
@keyframes slideIn {
from {
    opacity: 0;
    transform: translateX(100%);
}
to {
    opacity: 1;
    transform: translateX(0);
}
}

/* Apply Animation to Images */
.carousel-image {
width: 100%;
height: 100%;
object-fit: cover;
/*animation: slideIn 20s ease-in-out;*/
}

/* Responsive Design */
@media (max-width: 768px) {
.quote-overlay {
    font-size: 2.5rem;
    padding: 15px 30px;
}

.carousel-image {
    height: auto;
    padding: none;
}

.paragraph-background {
    padding: 20px;
}
}

/* Left and Right Arrow Styling */
.left-arrow, .right-arrow {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 2rem;
  padding: 10px;
  cursor: pointer;
  z-index: 1001;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}

/* Dots Navigation Styling */
.dots {
  position: absolute;
  bottom: 100px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.dot {
  height: 15px;
  width: 15px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.dot.active {
  background-color: #717171;
}

/* Logo Styling */
.logo-in-background {
  position: absolute;
  top: 20px;
  right: 15px;
  height: 110px;
  z-index: 1; /* Lower z-index to go under the ribbon */
}

/* Ribbon Styling */
.carousel::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8%;
  background: #ffffff;
  z-index: 2; /* Higher z-index to be above the logo */
}

/* Quote Overlay Styling */
.quote-overlay {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  padding: 20px 40px;
  border-radius: 10px;
  color: #fff;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.4;
  max-width: 80%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.part {
position: relative;
width: 100%;
padding: 40px 0;
background-color: #f5f5f5;
display: flex;
flex-direction: column;
align-items: center;
}

.part h2 {
font-size: 100px;
font-weight: bold;
text-align: center;
color: #b61f1f;
margin-bottom: 60px;
background: rgb(94, 92, 92);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
letter-spacing: 1.5px;
}

/* Responsive Design for Smaller Screens */
@media (max-width: 1150px) {
  .carousel-image {
    object-fit: contain; /* Ensure images fit within their container */
    height: 100%; /* Allow the image to scale dynamically */
    padding-bottom: 200px;
  }

  .dots {
    bottom: 250px; /* Adjust position to ensure visibility */
    gap: 5px; /* Reduce spacing between dots */
  }

  .dot {
    width: 12px;
    height: 12px;
  }
  
}

/* Responsive Design for Smaller Screens */
@media (max-width: 850px) {
  .carousel-image {
    object-fit: contain; /* Ensure images fit within their container */
    height: 100%; /* Allow the image to scale dynamically */
    padding-bottom: 250px;
  }

  .dots {
    bottom: 350px; /* Adjust position to ensure visibility */
    gap: 5px; /* Reduce spacing between dots */
  }

  .dot {
    width: 10px;
    height: 10px;
  }
}

/* Responsive Design for Smaller Screens */
@media (max-width: 605px) {
  .carousel-image {
    object-fit: contain; /* Ensure images fit within their container */
    height: 100%; /* Allow the image to scale dynamically */
    padding-bottom: 250px;
  }
}

/* Responsive Design for Smaller Screens */
@media (max-width: 515px) {
  .carousel-image {
    object-fit: contain; /* Ensure images fit within their container */
    height: 100%; /* Allow the image to scale dynamically */
    padding-bottom: 350px;
  }
}

/* Responsive Design for Smaller Screens */
@media (max-width: 340px) {
  .carousel-image {
    object-fit: contain; /* Ensure images fit within their container */
    height: 100%; /* Allow the image to scale dynamically */
    padding-bottom: 460px;
  }
}

/* Responsive Design for Smaller Screens */
@media (max-width: 768px) {

  .carousel {
    width: 100%;
    max-height: 80vh; /* Restrict height to prevent overflow */
  }
  .left-arrow, .right-arrow {
    font-size: 1.2rem; /* Slightly smaller arrows for smaller screens */
    padding: 5px;
  }

}

@media (max-width: 480px) {
  .left-arrow,
  .right-arrow {
    font-size: 1.2rem;
    padding: 6px;
  }

  .dots {
    bottom: 10px;
  }

  .dot {
    height: 8px;
    width: 8px;
  }

  .part {
    padding: 8px;
  }
  
  .part h2 {
    font-size: 1.5rem; /* Font size suitable for small mobile screens */
    padding-bottom: 15px;
  }
}

/* Image Rows */
.first-row, .second-row {
display: flex;
justify-content: space-between;
width: 100%;
}

/* Image Link */
.image-link {
position: relative;
display: inline-block;
width: 48%;
}

.image-link img {
width: 100%;
height: auto;
max-height: 300px;
object-fit: cover;
transition: transform 0.3s ease, box-shadow 0.3s ease;
box-shadow: 0 8px 10px rgba(0, 0, 0, 0.15);
}

.image-link:hover img {
transform: scale(1.05);
box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.image-link:hover .image-text {
opacity: 1;
}

/* Background Overlay */
.background-overlay {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background:#ffffff;
z-index: -1;
}

/* Second Part - Background and Animation */
.full-width-background2 {
position: relative;
width: 100%;
padding: 20px;
background: #ffffff;
opacity: 0;
transition: opacity 1s ease-in-out;
}

.animate-images {
opacity: 1;
}

/* Header Row */
.header-row {
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
gap: 50px;
margin-left: 40px;
margin-top: -60px;
}

.header-row h2 {
flex: 1;
margin: 0;
font-size: 100px;
padding-bottom: 50px;
}

.header-row .image-link {
flex: 1;
position: relative;
width: 100%;
}

/* Image Container */
.image-container {
display: flex;
justify-content: space-between;
align-items: center;
margin-top: 30px;
margin-left: 200px;
width: 100%;
}

.two{
  margin-left: -100px;
}

.one{
  margin-left: -12px;
}

.image-link img {
width: 100%;
height: 240px;
object-fit: cover;
max-width: 600px;
border: #464749 solid 10px;
}

/* Image Link Animation */
.image-link {
flex: 1;
position: relative;
transform: translateY(100%);
opacity: 0;
transition: transform 2s ease, opacity 1s ease;
}

.image-text {
position: absolute;
top: 50%;
left: 40%;
transform: translate(-50%, -50%);
color: #ffffff;
font-size: 40px;
font-weight: bold;
text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
white-space: nowrap;
opacity: 0;
transition: opacity 3s ease 0.5s, transform 4s ease 0.5s;
}

/* Animation effects */
.animate-images .image-link {
transform: translateY(0);
opacity: 1;
}

.animate-images .image-link .image-text {
opacity: 1;
transform: translate(-50%, -50%);
}

/* Fourth Part */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 20px;
  background: #f1f1f1;
}

.home-container {
  max-width: 1200px;
  text-align: center;
}

.home-content h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

.home-content p {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.8;
}







