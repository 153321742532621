/* Animation keyframes for sliding from down to up */
@keyframes slideUp {
    0% {
      opacity: 0;
      transform: translateY(50px); /* Start from below */
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* End at the original position */
    }
  }
  
  /* Animation keyframes for fading in tech container */
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px); /* Start from slightly below */
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* End at the original position */
    }
  }
  
 /* Animation keyframes for sliding buttons from left to right */
@keyframes slideInButtons {
    0% {
      opacity: 0;
      transform: translateX(-50px); /* Start from the left */
    }
    100% {
      opacity: 1;
      transform: translateX(0); /* End at the original position */
    }
  }
  
  /* Heading styles */
  .technologies-section h2 {
    font-size: 100px;
    margin-top: -50px;
    font-weight: bold;
    background: rgb(94, 92, 92);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-bottom: 40px;
    opacity: 0; /* Initially hidden */
    transform: translateY(50px); /* Start from below */
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  }
  
  /* Apply animation when the heading is visible */
  .heading.animate-heading {
    opacity: 1;
    transform: translateY(0); /* End at the original position */
    animation: slideUp 2s ease-out forwards; /* Slide from down to up */
  }
  
  .button-container {
    display: flex;
    justify-content: center; /* Align buttons to the left */
    margin: 20px 0; /* Add margin for spacing */
    width: 100%; /* Make the button container full width */
  }
  
  /* Default button style */
  .button-container button {
    background-color: rgb(66, 69, 70); /* Button background color */
    width: 330px;
    color: #b2b4b6; /* Button text color */
    border: none; /* Remove border */
    padding: 25px 40px; /* Add padding */
    margin: 0 10px; /* Add margin4e between buttons */
    font-size: 1rem; /* Button font size */
    text-transform: uppercase;
    font-weight: bold; /* Button font weight */
    cursor: pointer; /* Change cursor to pointer */
    border-radius: 5px; /* Rounded corners */
    transition: background-color 0.3s; /* Smooth background color transition */
    opacity: 0; /* Initially hidden */
    transform: translateX(-50px); /* Start from the left */
    animation: slideInButtons 2s ease-out forwards; /* Slide in from the left */
  }
  
  
  /* Style for the active main category button */
  .button-container button.active-main {
    background-color: rgb(66, 69, 70); /* Active state background color */
    color:  #e67839; /* Active state text color */
    border: 8px solid #b2b4b6 ; /* Add border to active button */
  }
  
  /* Hover state (optional for a nice hover effect) */
  .button-container button:hover {
    background-color: rgb(66, 69, 70); /* Change color on hover */
    color: #e67839;
  }
  
  /* Apply animation when buttons are visible */
  .button-container.animate-button-container {
    opacity: 1;
    transform: translateX(0); /* End at the original position */
    opacity: 1;
  animation: slideInButtons 2s ease-out forwards; /* Slide in from the left */
  }
  
  /* Tech container */
  .tech-container {
    opacity: 0; /* Initially hidden */
    transform: translateY(20px); /* Start slightly below */
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }
  
  /* Apply animation when the tech container is visible */
  .tech-container.animate-tech-container {
    opacity: 1;
    transform: translateY(0); /* End at the original position */
    animation: fadeIn 2s ease-out forwards; /* Fade in with a slight slide up */
  }
  
  /* Reset the heading and tech container when they leave the viewport */
  .heading,
  .tech-container {
    opacity: 0;
    transform: translateY(50px);
  }
  
  /* Ensure alignment with the overall design of Home.js */
  .technologies-section {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    padding: 80px 20px;
    position: relative;
  }
  
  .tech-container {
    display: flex;
    width: 100%;
    max-width: 1400px; /* Limit the width for a professional look */
  }
  
  .tech-tabs {
    display: flex;
    flex-wrap: wrap; /* Allow buttons to wrap into the next row */
    justify-content: flex-start; /* Align buttons to the left */
    width: 70%; /* Take up 70% of the container width */
    margin-right: 40px; /* Space between tabs and content */
    height: 240px; /* Set a fixed height for both containers */
    overflow-y: auto; /* Allow scrolling if the content exceeds the height */
  }
  
  .tab-button {
    background-color: #b2b4b6;
    color: rgb(66, 69, 70);
    padding: 15px 30px; /* Ensure padding is consistent */
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    margin: 10px; /* Space between buttons */
    border-radius: 25px; /* Rounded corners */
    flex: 0 0 calc(33.33% - 20px); /* Three buttons per row */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
    transition: all 0.3s ease-in-out;
    border: none;
  }
  
  .tab-button:hover {
    background-color: white;
    color: rgb(66, 69, 70);
    border:0;
  }
  
  .tab-button.active {
    background-color: white;
    color: rgb(66, 69, 70);
    border:0;
  }
  
  .tech-content {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap into the next row */
    width: 30%; /* Take up 30% of the container width */
    height: 240px; /* Set a fixed height for both containers */
    overflow-y: auto; /* Allow scrolling if the content exceeds the height */
  }
  
  .tech-item {
    background-color: #66ccff;
    padding: 15px 30px;
    border-radius: 5px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    font-size: 1.2rem;
    font-weight: 500;
    color: white;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    margin: 10px; /* Space between items */
    flex: 0 0 calc(50% - 20px); /* Two items per row */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
  }
  
  .tech-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .technologies-section h2 {
      font-size: 2rem;
    }
  
    .tab-button {
      font-size: 0.9rem;
      padding: 8px 15px;
    }
  
    .tech-item {
      padding: 10px 20px;
      font-size: 1rem;
    }
  
    .tech-tabs,
    .tech-content {
      width: 100%; /* Stack the columns on smaller screens */
      margin-right: 0; /* Remove margin */
      height: auto; /* Allow height to adjust on smaller screens */
    }
    .button-container button{
      width: 150px;
      font-size: 0.7rem;
      padding: 15px 22px;
    }
  }


@media (max-width: 200px) {
  .technologies-section h2 {
    font-size: 1rem; /* Smaller font size for very small screens */
  }
  .tab-button {
    font-size: 0.6rem;
    padding: 4px 8px;
  }
  .tech-item {
    padding: 5px 10px;
    font-size: 0.7rem;
  }
  .button-container button{
    width: 80px;
    font-size: 0.5rem;
    padding: 8px 11px;
  }
}
  