.all-service-container {
    font-family: 'Roboto', sans-serif;
    color: #333;
    margin: 0;
    padding: 0;
    background-color: #f2f4f6;
  }
  
  /* Hero Section */
  .all-service-hero {
    text-align: center;
    padding: 150px 40px;
    background-color: #f8f6f3;
    color: #66ccff;
    margin-top: 0;
    height: 60vh;
    animation: fadeIn 1s ease-in-out;
  }
  
  .all-service-hero h1 {
    font-size: 80px;
    margin-bottom: 15px;
    letter-spacing: 2px;
  }
  
  .all-service-hero p {
    font-size: 22px;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.8;
    color: rgb(93, 124, 152);
  }
  
 /* Details Section: Why Choose Saffroot and Our Recruitment Process */
.all-service-details {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(1000px, 1fr)); /* Responsive grid layout */
    gap: 40px;
    padding-top: 60px;
    padding-right: 60px;
    padding-left: 60px;
    background-color: #fff;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.05); /* Professional shadow */
    border-radius: 12px;
    margin-bottom: 0;
  }
  
  /* Recruitment Details Box Animation */
.all-service-details-box {
  padding: 50px 20px;
  background-color: #fff;
  transition: transform 0.5s ease-in-out, box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
  position: relative;
  opacity: 0; /* Initial state for fade-in animation */
  transform: translateY(30px) scale(0.95) rotate(2deg); /* Initial position and slight zoom out with rotation */
  animation: fadeInUpZoom 0.8s forwards ease-in-out; /* Animate on load */
}

.additional-space {
  margin-bottom: 100px;
}

/* Staggered animation delay for boxes */
.all-service-details-box:nth-child(1) {
  animation-delay: 0.3s;
}

.all-service-details-box:nth-child(2) {
  animation-delay: 0.6s;
}

.all-service-details-box:nth-child(3) {
  animation-delay: 0.9s;
}

/* Enhanced Fade-in, Zoom, and Rotate Animation */
@keyframes fadeInUpZoom {
  0% {
      opacity: 0;
      transform: translateY(30px) scale(0.95) rotate(2deg); /* Starting from a slightly smaller size and rotation */
  }
  60% {
      opacity: 0.8;
      transform: translateY(10px) scale(1.05) rotate(0deg); /* Grow slightly larger mid-way through animation */
  }
  100% {
      opacity: 1;
      transform: translateY(0) scale(1) rotate(0deg); /* Final state: fully visible and at normal size */
  }
}
  
  /* Title Styling */
  .all-service-details-box h2 {
    font-size: 40px;
    color: #66ccff;
    margin-bottom: 20px;
    font-weight: 600;
    position: relative;
  }
  
  
  /* Paragraph Styling */
  .all-service-details-box p {
    font-size: 18px;
    line-height: 1.8;
    color: rgb(93, 124, 152);
    margin-bottom: 15px;
    padding-left: 15px;
  }
  
  /* Service Section */
  .all-service-services {
    padding: 60px 5%;
    background-color: #fff;
  }
  
  .all-service-services h2 {
    font-size: 60px;
    text-align: center;
    margin-bottom: 40px;
    margin-top: 0;
    color: #66ccff;
    opacity: 0; /* Initially hidden */
    transform: scale(0.9); /* Initial state for scaling effect */
    animation: fadeInScale 1.2s ease-in-out forwards; /* Animation */
  }
  
  /* Animation keyframes */
  @keyframes fadeInScale {
    0% {
      opacity: 0;
      transform: scale(0.9); /* Slightly smaller */
    }
    50% {
      opacity: 0.5;
      transform: scale(1.1); /* Slightly larger in the middle */
    }
    100% {
      opacity: 1;
      transform: scale(1); /* Final normal size */
    }
  }
  
  .service-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
    margin-bottom: 40px;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 1s forwards ease-in-out;
    padding-right: 20px;
  }
  
  .service-item:nth-child(even) {
    animation-delay: 0.2s;
  }
  
/* Glowing border and box-shadow on hover */
.service-item:hover {
  transform: scale(1.05) rotate(0deg);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2), 0 0 10px #f3f2f2; /* Added glow */
  background-color: #f8f6f3;
}
  
  /* Service Heading Box */
  .service-heading-box {
    flex: 1;
    padding: 80px;
    margin-right: 25px;
    background-color: #f8f9fa;
    border-left: 15px solid #f8f6f3;
    text-align: center;
    max-width: 350px;
    height: 200px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease-in-out;
  }
  
  .service-heading-box h3 {
    font-size: 38px;
    color: #4a4a4a;
  }
  
  /* Service Content Box */
  .service-content-box {
    flex: 2;
    padding: 40px;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .service-content-box p {
    font-size: 18px;
    color: #555;
    line-height: 1.6;
  }
  
  /* Flex Layout */
  .service-item {
    height: auto;
    margin-bottom: 40px;
    border-radius: 10px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.05);
  }
  
  /* Animation styling */
.fade-div {
  background: #ffffff;
  padding: 15px 40px;
  margin: 10px 0;
  border-radius: 6px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.fade-item:hover {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease;
}

/* Typography enhancements */
.fade-item::before {
  font-size: 1.2rem;
  color: #3498db;
  padding-right: 5px;
}
  /* Animation */
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Hero Section Animation */
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  /* Responsive Adjustments */
  @media (max-width: 900px) {
    .service-item {
      flex-direction: column;
      text-align: center;
      margin-bottom: 40px;
    }
  
    .service-heading-box {
      margin-right: 0;
      margin-bottom: 20px;
      border-radius: 8px;
      height: auto;
    }
  
    .service-content-box {
      border-radius: 8px;
    }
  }
  